import { Component, HostListener, Input, EventEmitter, Output, OnInit, ViewChild, ElementRef, OnDestroy, AfterContentChecked } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AllowedFileTypeModalComponent } from 'src/app/designer/preview/page-content/upload-page/allowed-file-type-modal/allowed-file-type-modal.component';
import { ModalVerticalSizeWindowClass, ModalWidth } from '../ey-modal-template/ey-modal-result.enum';
import { FileExtension, FileTypes, EyFileUploadService, ExtensionTypes } from './ey-file-upload.service';
import * as uuid from 'uuid';
import { Subject } from 'rxjs';
import { FileUploadResult } from 'src/app/core/file-upload.service';

export const DEFAULT_MAX_FILES = 5;
export const DEFAULT_MAX_FILESIZE_IN_MB = 64;

const MAX_FILE_SIZE_TOKEN = '#MAXFILES';

export const ERROR_MSG = {
  invalidExtension: 'This file type is not supported.',
  fileSize: 'Maximum file size supported is 64MB. ',
  fileAnySize: 'File length must not be 0.',
  filesLimitReached: `You can upload maximum ${MAX_FILE_SIZE_TOKEN} files.`,
  invalidVideoExtension: 'Selected type of file is not supported.',
};

export enum FileUploadStatus {
  inProgress = 'In Progress',
  uploaded = 'Success',
  error = 'Maximum file size supported is 64MB.',
}

export interface FileListItem {
  fileId: string;
  file: File;
  status: FileUploadStatus;
  uploadResult?: FileUploadResult | undefined;
  fileExtension?: FileExtension;
  errorMsg?: string;
  visible?: boolean;
}

export interface ToggleEventArg {
  fileId: string;
  visible: boolean;
}

const UPLOAD_FILE = 'Upload file to proceed.';
const UPLOAD_IMAGE = 'Upload image file to proceed.';

@Component({
  selector: 'ey-file-upload',
  templateUrl: './ey-file-upload.component.html',
  styleUrls: ['./ey-file-upload.component.scss'],
})
export class EyFileUploadComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('file') myInput: ElementRef;
  @ViewChild('fileTemplate') fileTemplate: ElementRef;
  @Input() warningOnVideoUpload: string = null;
  @Input() acceptedFormats = '';
  @Input() increaseFixedWidth = false;
  @Input() decreaseFixedWidth = false;
  @Input() docType: ExtensionTypes;
  @Input() hasError = false;
  @Input() isRequired = false;
  @Input() subHeaderLink = false;
  @Input() fileExt: FileExtension[] = [FileTypes.video];
  @Input() maxFiles = DEFAULT_MAX_FILES;
  @Input() maxSize = DEFAULT_MAX_FILESIZE_IN_MB;
  @Input() filesSubTitle = 'Please Provide Files Sub Title';
  @Input() dragAndDropTitle = 'Drag and drop or';
  @Input() dragAndDropSubTitle = '';
  @Output() initFileUpload = new EventEmitter<FileListItem>();
  @Output() deleteFile = new EventEmitter<FileListItem>();
  @Output() toggleVisibility = new EventEmitter<ToggleEventArg>();
  @Input() showVisibilityColumn = false;
  @Input() files: FileListItem[] = [];
  @Input() requiredErrMsg = '';
  @Input() allowFileDownload: boolean;
  @Output() initFileDownload = new EventEmitter<FileListItem>();

  #destroy$: Subject<void> = new Subject<void>();

  modalOptions: NgbModalOptions = {
    animation: false,
    backdrop: 'static',
    size: ModalWidth.Large,
    windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader,
  };
  indexToRemove: number;
  customWidthClass: string;

  get uploadInProgress(): boolean {
    return this.files.find((f) => f.status === FileUploadStatus.inProgress) !== undefined;
  }

  fileUploadStatuses = FileUploadStatus;
  dragAreaClass = 'dragarea';

  onVisibilityToggle(fid: string, event: any): void {
    this.toggleVisibility.emit({ fileId: fid, visible: event });
  }

  ngAfterContentChecked(): void {
    this.customWidthClass = this.settingCustomWidthCalss(this.fileTemplate?.nativeElement?.innerText?.length, this.fileTemplate?.nativeElement?.parentElement?.offsetWidth);
  }

  getToggleValues(file: FileListItem): boolean {
    if (file.visible === true) {
      return true;
    } else if (file.visible === false) {
      return false;
    } else {
      return true;
    }
  }

  get validFiles(): FileListItem[] {
    return this.files.filter((f) => f.status !== FileUploadStatus.error);
  }

  get errors(): FileListItem[] {
    return this.files.filter((f) => f.status === FileUploadStatus.error);
  }

  get isDisabled(): boolean {
    return this.files.length >= this.maxFiles;
  }

  constructor(
    private eyFileUploadService: EyFileUploadService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    if (this.requiredErrMsg?.length < 1) {
      this.requiredErrMsg = this.subHeaderLink ? UPLOAD_FILE : UPLOAD_IMAGE;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(event): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    const files = event.dataTransfer.files;
    this.uploadFiles(files);
  }

  onFileChange(event): void {
    const files = event.target.files;
    this.uploadFiles(files);
  }

  uploadFiles(files: File[]): void {
    for (let ctr = 0; ctr < files.length; ctr++) {
      const f = files[ctr];
      const fi = this.validateAndConvertToFileListItem(f);
      this.files.push(fi);
      if (fi.status === FileUploadStatus.inProgress) {
        this.initFileUpload.emit(fi);
      }
    }
  }

  // Note: Date.now().toString() can still generate the same string. Hence, changing tempId to use uuid so it stays unique.
  private validateAndConvertToFileListItem(file: File): FileListItem {
    const fli: FileListItem = {
      file: file,
      status: FileUploadStatus.error,
      fileId: uuid.v4(),
    };
    fli.fileExtension = this.eyFileUploadService.getFileExtension(file.name, this.docType);
    if (fli.fileExtension === undefined) {
      fli.errorMsg = this.docType === ExtensionTypes.videoFile ? ERROR_MSG.invalidVideoExtension : ERROR_MSG.invalidExtension;
      return fli;
    }

    if (this.files.length >= this.maxFiles) {
      fli.errorMsg = ERROR_MSG.filesLimitReached.replace(MAX_FILE_SIZE_TOKEN, this.maxFiles.toString());
      return fli;
    }

    if (!this.isAnyFileSize(file)) {
      fli.errorMsg = ERROR_MSG.fileAnySize;
      return fli;
    }

    if (!this.isValidFileSize(file)) {
      fli.errorMsg = ERROR_MSG.fileSize;
      return fli;
    }

    fli.status = FileUploadStatus.inProgress;

    return fli;
  }

  private isAnyFileSize(file: File): boolean {
    return file.size > 0;
  }

  private isValidFileSize(file: File): boolean {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    return this.maxSize > size;
  }

  onSeeAllowedTyped(): void {
    this.modalService.open(AllowedFileTypeModalComponent, {
      ...this.modalOptions,
      windowClass: ModalVerticalSizeWindowClass.auto,
      size: ModalWidth.default,
    });
  }

  delete(file: FileListItem): void {
    this.indexToRemove = null;
    this.files.forEach((f, index) => {
      if (f.fileId === file.fileId && f.file.name === file.file.name) {
        this.indexToRemove = index;
      }
    });
    if (this.indexToRemove !== null) {
      this.files.splice(this.indexToRemove, 1);
    }
    this.myInput.nativeElement.value = null;
  }

  deleteAndEmit(file: FileListItem): void {
    this.delete(file);
    this.deleteFile.emit(file);
  }

  markFileAsUploaded(fileUploadResult: FileUploadResult): void {
    const cf = this.files.find((f) => f.fileId == fileUploadResult.tempId && f.status === FileUploadStatus.inProgress);
    if (cf !== undefined) {
      cf.status = FileUploadStatus.uploaded;
      cf.uploadResult = fileUploadResult;
      this.files = [...this.files];
    }
  }

  setError(id: string, errorMsg: string): void {
    const cf = this.files.find((f) => f.fileId === id);
    if (cf !== undefined) {
      cf.status = FileUploadStatus.error;
      cf.errorMsg = errorMsg;
    }
  }

  //@ToDo: Check for better solution in future to handle css operation.
  settingCustomWidthCalss(innerTextLength: number, offSetWidth: number): string {

    if (innerTextLength === 0 && offSetWidth > 800) {
      return 'change-fixed-width';
    }

    if (innerTextLength && offSetWidth > 1400) {
      return 'change-fixed-width';
    }

    if (innerTextLength && offSetWidth < 800) {
      return this.decreaseFixedWidth ? 'custom-width-for-tool' : 'customWidth';
    }

    return 'customWidth';
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
  }
}
